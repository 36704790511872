@tailwind base;
@tailwind components;
@tailwind utilities;

.section-rounded-b {
    @apply rounded-b-3xl md:rounded-b-[5rem];
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.thumbnail .keen-slider__slide.active {
    @apply ring-primary
}

.font-gradient {
    background: linear-gradient(to right bottom,#fff 30%,hsla(0,0%,100%,.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: unset;
}

.font-effect-hero {
    -webkit-text-stroke: .4px #ffffff80;
    -webkit-text-fill-color: transparent;
    text-shadow: -1px -1px 0 hsla(0,0%,100%,.2),1px 1px 0 rgba(0,0,0,.1);
}
